h3 {
    text-transform:uppercase;
}
p {
    line-height: 38px;
}
span.sp1 {
    font-size:19px;
    padding-left:5px;
}

div.notification-one {
    background: #fbf5c8 !important;
}