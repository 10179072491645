
/* --------------------   errors  */
.error-msg {
  clear:both;
  color:#8e1310;
  font-size:17px !important;
  padding:8px !important;
  margin:20px 0 0 0;
  float:left;
  font-family:Myfont, Helvetica, Arial, sans-serif;
  display:block;
  background: #fff9ed url(../../../public/images/arrowup.gif) no-repeat;
  background-position:10px 12px;
  border-radius:6px;
  border:1px solid #f5e7cd;
  text-indent:20px;
  width:98% !important;
}

.error-msg-2 {
  clear:both;
  color:#8e1310;
  font-size:21px !important;
  margin:20px 0 0 0;
  padding:20px;
  text-align:center;
  font-family:Myfont, Helvetica, Arial, sans-serif;
  display:block;
}
.error-msg-4 {
  clear:both;
  color:#8e1310;
  font-size:17px !important;
  padding:8px !important;
  margin:20px 0 0 0;
  font-family:Myfont, Helvetica, Arial, sans-serif;
  display:block;
  border-radius:6px;
  border:1px solid #f5e7cd;
  width:98% !important;
  text-align: left;
}
/*
.error-msg-2 {
  clear:both;
  color:#8e1310;
  font-size:16px;
  margin:0px 0 0 0;
  padding:0 0 0 8px;
  float:left;
  font-family:Myfont, Helvetica, Arial, sans-serif;
  display:block;
  background: url(../../../public/images/arrowup.gif) no-repeat;
  background-position:10px 7px;
  text-indent:20px;
  width:98% !important;

}
*/
