div.div_search {
    width:100%;
    float:left;
}
.search-input{
    width:240px !important;
    height:55px;
    margin-top:20px !important;
}
.relative {
    position:relative;
}
a.clean_btn {
    width:30px;
    height:30px;
    float:left;
    right:94px;
    top:35px;
    color:#d2d2d2 !important;
    position:absolute;
    z-index:400;
}
a.clean_btn svg {
    font-size:21px;
}
button.search-top-btn {
    height:54px;
    min-width:54px !important;
    margin-left:5px;
    border-radius:6px;

}
button.search-top-btn svg {
    font-size:17px;
    padding-left:10px;
}