/* ------------------------------loading */
.loading_bg {
   /* background: rgba(0,0,0,0.4) !important;*/
  min-height: 100%;
  width:100vw;
  position: absolute;
  z-index:99999;
}
.loading_bg  .mloading-bar {
  width: 250px;
  min-height: 22px;
  text-align: center;
  position: absolute;
  top: calc(50% - 100px)  !important;
  left:calc(50%  - 170px)!important;
}

.loading_bg_side {
  /* background: rgba(0,0,0,0.4) !important;*/
  width:100%;
  position: absolute;
  z-index:99999;
}
.loading_bg_side  .mloading-bar {
  width: 250px;
  min-height: 22px;
  text-align: center;
  position: absolute;
  top: 200px  !important;
  left:calc(50%  - 170px)!important;
}

.loading_bg p,.loading_bg_side p {
  font-size:18px;
  font-family:EffraLt, Helvetica, Arial, Sans-Serif;
  text-align:center;
  padding:30px;
  display:block;
  color:#000;
}

.displayisLoading {
  opacity: 0.3;
}


