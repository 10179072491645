/* HTML elements  */
@import "./components/loading.css";
@import "./components/error.css";
@import "./components/inputs.css";
/*@import "./components/quasarComponents.css";*/

/*searchdropbox box_select_two */
/* HTML elements  */
@font-face {
  font-family: Myfont;
  src: url("../fonts/PTC55F.eot") /* EOT file for IE */
}
@font-face {
  font-family: Myfont;
  src: url("../fonts/PTC55F.ttf") /* TTF file for CSS3 browsers */
}

@font-face {
  font-family: Myfont2;
  src: url("../fonts/DroidSans.eot") /* EOT file for IE */
}
@font-face {
  font-family: Myfont2;
  src: url("../fonts/DroidSans.ttf") /* TTF file for CSS3 browsers */
}

@font-face {
  font-family: Myfont3;
  src: url("../fonts/PTS75F.eot") /* EOT file for IE */
}
@font-face {
  font-family: Myfont3;
  src: url("../fonts/PTS75F.eot") /* EOT file for IE */
}



/****************************************  fonts  */
h1 {font-family:Myfont3, Helvetica, Arial, Sans-Serif;}
h2 , h3 , h4 , h5 , h6 , ul li a {font-family:Myfont, Helvetica, Arial, Sans-Serif;}

.title { font-weight:normal !important;}

body, table, input, textarea, select, li, button{
  font:1em Myfont2, Helvetica, Arial, Sans-Serif;

}

section.main_content.active {
  opacity: 0.5;
  filter: alpha(opacity=50); /* For IE8 and earlier */
}

.isRelative {
  position: relative;
}



/* ------------- slide nimation */

.animated {
  transition: all 400ms;
  position: absolute;
  transform: translate(-50%, -50%);
}

.slide-in {
  opacity: 0;
  transform: translate(-40%, -50%);
}

.slide-in-active {
  transition-delay: 150ms;
}

.slide-out {
  opacity: 1;
}

.slide-out-active {
  opacity: 0;
  transform: translate(-60%, -50%);
}



div.list_slider ul {
  padding:20px 0 0 0;

}


div.slide_inner{
  transition:margin 0.6s ease-out;

}

div.list_slider ul li{

  display:block;

}

div.list_slider ul p {

  display:block;
}


.img_r1 {
  border-radius:6px;
}


a.thumb.imgthumb1:hover {
  border-color:#1b7694;


  /* background:#a8d7e7;
  opacity: 0.6;*/


}



a.thumb.imgthumb1 img {

  position: absolute;
  left: 50%;
  top: 50%;
  height: 100%;
  width: auto;
  -webkit-transform: translate(-50%,-50%);
  -ms-transform: translate(-50%,-50%);
  transform: translate(-50%,-50%);


}

ul.more-dt-login  li {
  padding:10px 0 10px 0;
}

/***  menu_type_slider_one */
ul#menu_type_slider_one {
  margin:0px auto;
  padding:10px 0 20px 0;
  display: flex;
  justify-content: center;

}
ul#menu_type_slider_one li {

  display: inline-block;
  padding:0 10px 0 0;
}
ul#menu_type_slider_one li a {
  border-radius:6px;
  background:#eeeeee;
}
ul#menu_type_slider_one li a.active {
  color:#FFF;
  background:#579d3a;
}

ul#menu_type_slider_one li a:hover {
  background:#e0e0e0;
  color:#114a6a;
}

ul#menu_type_slider_one li a.active:hover {
  background:#579d3a;
  color:#FFF;
}

/*********************** slide_display_property ul */
div.slide_display_property ul {
  height:210px;
  white-space: nowrap;
  overflow: hidden;
  width:100%;

}

div.slide_display_property ul li{
  width:280px;
  display: inline-block;
  padding:0 10px 0 0;

}

div.shadow-box-lm {
  position:relative;
  border-radius:6px;
  margin:15px;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  -webkit-box-shadow: 2px 2px 6px 0 rgba(0,0,0,0.31) ;
  box-shadow: 2px 2px 6px 0 rgba(0,0,0,0.31) ;
  padding:0px;
  overflow:hidden;
  background:#FFF;
}

/******************************** BUTTONS */
/* --------------------------------------- GREEN TOP */
div.btn_col_top_search_ch {
  width:165px;
  padding:0px;
  margin:0px;
}
div.btn_col_top_search_ch.loading_col {
  width:190px;
}
div#searchhometop, div#searchhometop div.column {
  padding:0px !important;
  margin:0px !important;;
}
/* ----------------------------------------------  SEARCHHOMETOP */
button.submit_green_src_top {
  height:60px;
  width:165px;
  padding:0px;
  margin:0px;
  text-indent:20px;
  line-height:50px;
  cursor: pointer;
  border: 0px solid;
  -webkit-border-radius: 0 6px 6px 0 / 6px;
  border-radius: 0 6px 6px 0 / 6px;
  color: #FFF !important;
  background-color: #3c8120;
  -webkit-box-shadow: 2px 2px 2px 1px rgba(0,0,0,0.2) ;
  box-shadow: 2px 2px 2px 1px rgba(0,0,0,0.2) ;
}
button.submit_green_src_top span {
  font-size:19px;
}
button.submit_green_src_top:hover {
  background-color: #4fa527;
}
button.submit_green_src_top.btn-loading{
  width:190px;
}
button.submit_green_src_top svg {
  margin-left:8px;
}
/* -------- BLUE TOP */
button.top-blue-search-btn {
  display: inline-block;
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
  cursor: pointer;
  padding: 10px 20px;
  border: 1px solid #1b7da0;
  -webkit-border-radius: 6px;
  border-radius: 6px;
  -o-text-overflow: clip;
  text-overflow: clip;
  background: -webkit-radial-gradient(closest-side, rgba(0,0,0,0) 0, rgba(24,140,178,1) 100%), #0199d9;
  background: -moz-radial-gradient(closest-side, rgba(0,0,0,0) 0, rgba(24,140,178,1) 100%), #0199d9;
  background: radial-gradient(closest-side, rgba(0,0,0,0) 0, rgba(24,140,178,1) 100%), #0199d9;
  background-position: 50% 50%;
  -webkit-background-origin: padding-box;
  background-origin: padding-box;
  -webkit-background-clip: border-box;
  background-clip: border-box;
  -webkit-background-size: auto auto;
  background-size: auto auto;
  -webkit-box-shadow: 2px 2px 2px 0 rgba(0,0,0,0.2) ;
  box-shadow: 2px 2px 2px 0 rgba(0,0,0,0.2) ;
  text-shadow: -1px -1px 0 rgba(15,73,168,0.66) ;
  color: #FFF !important;
  height:35px !important;
  width:144px;
}

button.top-blue-search-btn:hover {
  background: -webkit-radial-gradient(closest-side, rgba(0,0,0,0) 0, rgba(38,149,183,1) 100%), #0e98ce;
  background: -moz-radial-gradient(closest-side, rgba(0,0,0,0) 0, rgba(38,149,183,1) 100%), #0e98ce;
  background: radial-gradient(closest-side, rgba(0,0,0,0) 0, rgba(38,149,183,1) 100%), #0e98ce;
  background-position: 50% 50%;
  -webkit-background-origin: padding-box;
  background-origin: padding-box;
  -webkit-background-clip: border-box;
  background-clip: border-box;
  -webkit-background-size: auto auto;
  background-size: auto auto;
  color: #FFF !important;

}


button.submit-green-btn {
  width: 100%;
  display: inline-block;
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
  cursor: pointer;
  padding: 16px 20px;
  border: 1px solid #34722a;
  -webkit-border-radius: 25px;
  border-radius: 8px;
  color: rgba(255,255,255,0.9);
  -o-text-overflow: clip;
  text-overflow: clip;
  background: -webkit-radial-gradient(closest-side, rgba(142,173,76,1) 0, rgba(115,168,18,1) 100%);
  background: -moz-radial-gradient(closest-side, rgba(142,173,76,1) 0, rgba(115,168,18,1) 100%);
  background: radial-gradient(closest-side, rgba(142,173,76,1) 0, rgba(115,168,18,1) 100%);
  background-position: 50% 50%;
  -webkit-background-origin: padding-box;
  background-origin: padding-box;
  -webkit-background-clip: border-box;
  background-clip: border-box;
  -webkit-background-size: auto auto;
  background-size: auto auto;
  -webkit-box-shadow: 2px 2px 2px 0 rgba(0,0,0,0.2) ;
  box-shadow: 2px 2px 2px 0 rgba(0,0,0,0.2) ;
  text-shadow: -1px -1px 0 rgba(15,73,168,0.66) ;
  -webkit-transition: all 300ms cubic-bezier(0.42, 0, 0.58, 1);
  -moz-transition: all 300ms cubic-bezier(0.42, 0, 0.58, 1);
  -o-transition: all 300ms cubic-bezier(0.42, 0, 0.58, 1);
  transition: all 300ms cubic-bezier(0.42, 0, 0.58, 1);
}


button.submit-green-btn:hover {
  border: 1px solid #39962b;
  background: -webkit-radial-gradient(closest-side, rgba(143,173,78,1) 0, rgba(129,175,36,1) 100%);
  background: -moz-radial-gradient(closest-side, rgba(143,173,78,1) 0, rgba(129,175,36,1) 100%);
  background: radial-gradient(closest-side, rgba(143,173,78,1) 0, rgba(129,175,36,1) 100%);
  background-position: 50% 50%;
  -webkit-background-origin: padding-box;
  background-origin: padding-box;
  -webkit-background-clip: border-box;
  background-clip: border-box;
  -webkit-background-size: auto auto;
  background-size: auto auto;
}

button.submit-green-btn svg {
  font-size:22px;
  margin-right:20px;
}

button.submit-green-btn span {
  font-family:Myfont, Helvetica, Arial, Sans-Serif;
}

button.btcenterones {
  width: 300px !important;
}

button.btn-submit-blue-one {
  width:300px;
  display: inline-block;
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
  cursor: pointer;
  border: 1px solid #018dc4;
  -webkit-border-radius:10px;
  border-radius: 10px;
  color: #FFF;
  -o-text-overflow: clip;
  text-overflow: clip;
  background: -webkit-radial-gradient(closest-side, rgba(65,132,198,1) 0, rgba(63,128,193,1) 100%);
  background: -moz-radial-gradient(closest-side, rgba(65,132,198,1) 0, rgba(63,128,193,1) 100%);
  background: radial-gradient(closest-side, rgba(65,132,198,1) 0, rgba(63,128,193,1) 100%);
  background-position: 50% 50%;
  -webkit-background-origin: padding-box;
  background-origin: padding-box;
  -webkit-background-clip: border-box;
  background-clip: border-box;
  -webkit-background-size: auto auto;
  background-size: auto auto;
}

button.btn-submit-blue-one:hover {
  color: #FFF;
  background: -webkit-radial-gradient(closest-side, rgba(69,136,198,1) 0, rgba(61,134,204,1) 100%);
  background: -moz-radial-gradient(closest-side, rgba(69,136,198,1) 0, rgba(61,134,204,1) 100%);
  background: radial-gradient(closest-side, rgba(69,136,198,1) 0, rgba(61,134,204,1) 100%);
  background-position: 50% 50%;
  -webkit-background-origin: padding-box;
  background-origin: padding-box;
  -webkit-background-clip: border-box;
  background-clip: border-box;
  -webkit-background-size: auto auto;
  background-size: auto auto;
}
button.btn-submit-blue-one:focus {
  color: #FFF;
}
button.btn-submit-blue-one svg {
  font-size:22px;
  margin-right:20px;
}

button.btn-submit-blue-one span {
  font-family:Myfont, Helvetica, Arial, Sans-Serif;
  font-size:20px;
}

/* ----------------  GREY BUTTON */
.toogleLink svg {
  font-size:18px;
  margin-right:16px;
}
.toogleLink {
  display: inline-block;
  font-size:16px;
  color:#256089;
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
  cursor: pointer;
  padding: 16px 20px;
  border: 1px solid #FFF;
  -webkit-border-radius: 6px;
  border-radius: 6px;
  background: -webkit-radial-gradient(closest-side, rgba(237,237,237,1) 0, rgba(239,239,239,1) 51%, rgba(229,229,229,1) 100%);
  background: -moz-radial-gradient(closest-side, rgba(237,237,237,1) 0, rgba(239,239,239,1) 51%, rgba(229,229,229,1) 100%);
  background: radial-gradient(closest-side, rgba(237,237,237,1) 0, rgba(239,239,239,1) 51%, rgba(229,229,229,1) 100%);
  background-position: 50% 50%;
  -webkit-background-origin: padding-box;
  background-origin: padding-box;
  -webkit-background-clip: border-box;
  background-clip: border-box;
  -webkit-background-size: auto auto;
  background-size: auto auto;
  -webkit-box-shadow: 2px 2px 2px 0 rgba(0,0,0,0.2) ;
  box-shadow: 2px 2px 2px 0 rgba(0,0,0,0.2) ;
  font-family:Myfont, Helvetica, Arial, Sans-Serif;

}

.toogleLink:hover {
  background: -webkit-radial-gradient(closest-side, rgba(242,242,242,1) 0, rgba(242,242,242,1) 51%, rgba(234,234,234,1) 100%);
  background: -moz-radial-gradient(closest-side, rgba(242,242,242,1) 0, rgba(242,242,242,1) 51%, rgba(234,234,234,1) 100%);
  background: radial-gradient(closest-side, rgba(242,242,242,1) 0, rgba(242,242,242,1) 51%, rgba(234,234,234,1) 100%);
  background-position: 50% 50%;
  -webkit-background-origin: padding-box;
  background-origin: padding-box;
  -webkit-background-clip: border-box;
  background-clip: border-box;
  -webkit-background-size: auto auto;
  background-size: auto auto;
}





.more-detail-one-btn {
  display: inline-block;
  cursor: pointer;
  padding: 12px 24px;
  -webkit-border-radius: 6px;
  border-radius: 6px;
  color:#FFF;
  background: -webkit-radial-gradient(closest-side, rgba(140,178,58,1) 0, rgba(127,158,56,1) 100%);
  background: -moz-radial-gradient(closest-side, rgba(140,178,58,1) 0, rgba(127,158,56,1) 100%);
  background: radial-gradient(closest-side, rgba(140,178,58,1) 0, rgba(127,158,56,1) 100%);
  background-position: 50% 50%;
  -webkit-background-origin: padding-box;
  background-origin: padding-box;
  -webkit-background-clip: border-box;
  background-clip: border-box;
  -webkit-background-size: auto auto;
  background-size: auto auto;
  -webkit-box-shadow: 1px 1px 1px 0 rgba(0,0,0,0.2) ;
  box-shadow: 1px 1px 1px 0 rgba(0,0,0,0.2) ;
  margin-top:20px;
  text-transform:uppercase;
  font-size:16px;
}
.more-detail-one-btn:hover {
  background: -webkit-radial-gradient(closest-side, rgba(153,191,65,1) 0, rgba(147,178,74,1) 100%);
  background: -moz-radial-gradient(closest-side, rgba(153,191,65,1) 0, rgba(147,178,74,1) 100%);
  background: radial-gradient(closest-side, rgba(153,191,65,1) 0, rgba(147,178,74,1) 100%);
  background-position: 50% 50%;
  -webkit-background-origin: padding-box;
  background-origin: padding-box;
  -webkit-background-clip: border-box;
  background-clip: border-box;
  -webkit-background-size: auto auto;
  background-size: auto auto;
  color:#FFF;
}
.more-detail-one-btn svg {
  margin-right:12px;

}


/* ----------------------------  btnredone */
a.btnredone {
  font-family:Myfont, Helvetica, Arial, Sans-Serif;
  padding:12px 0 12px 0;
  font-size:18px;
  width:100%;
  text-align:center !important;
  text-transform:uppercase !important;
  color:#0e4479 !important;

  display: inline-block;
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
  cursor: pointer;
  border: 1px solid #e8e8e8;
  -webkit-border-radius: 6px;
  border-radius: 50px;

  -o-text-overflow: clip;
  text-overflow: clip;
  background: -webkit-radial-gradient(closest-side, #f7f7f7 0, #f4f4f4 100%);
  background: -moz-radial-gradient(closest-side, #f7f7f7 0, #f4f4f4 100%);
  background: radial-gradient(closest-side, #f7f7f7 0, #f4f4f4 100%);
  background-position: 50% 50%;
  -webkit-background-origin: padding-box;
  background-origin: padding-box;
  -webkit-background-clip: border-box;
  background-clip: border-box;
  -webkit-background-size: auto auto;
  background-size: auto auto;

}
a.btnredone:hover {
  background: #1d66af;
  -webkit-background-origin: padding-box;
  background-origin: padding-box;
  -webkit-background-clip: border-box;
  background-clip: border-box;
  -webkit-background-size: auto auto;
  background-size: auto auto;
  color:#FFF !important;
}

button.top-blue-search-btn.btn-loading{
  width:144px;
}

button.top-blue-search-btn svg {
  font-size:20px;
}

button.top-blue-search-btn span {
  padding-left: 15px;
  font-size:20px;
}

button.mb-mobile-button {
  padding:18px 20px 18px 20px;
  border-radius:6px;
}
button.mb-mobile-button svg{
  margin-right:16px;
}
button.mb-mobile-button .span{
  font-size:17px;
  line-height:26px;
  font-family:Myfont, Helvetica, Arial, Sans-Serif;
}

.mb-btn-one {
  font-family:Myfont, Helvetica, Arial, Sans-Serif;
  padding:22px 25px 22px 25px;
  border-radius:6px;
}
.mb-btn-one .span{
  font-size:17px;
}
.mb-btn-one svg {
  margin-right:20px;
}

svg.MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.MuiSelect-icon {
  margin-right:10px;
  margin-top:-5px;
  font-size:25px;
}

div.MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-colorPrimary.MuiInputBase-formControl.css-1yk1gt9-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root, div.MuiSelect-select.MuiSelect-outlined.MuiSelect-multiple.MuiInputBase-input.MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input, div.MuiInputBase-root.MuiOutlinedInput-root {
  padding-top:0px !important;
  margin-top:0px !important;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  border: 1px solid #b7b7b7;
  -webkit-border-radius: 5px;
  border-radius: 5px;
  -o-text-overflow: clip;
  text-overflow: clip;
  background: rgba(252,252,252,1);
  -webkit-box-shadow: 3px 2px 4px -1px rgba(0,0,0,0.2) inset;
  box-shadow: 3px 2px 4px -1px rgba(0,0,0,0.2) inset;
  min-height : 56px !important;
}
div.MuiSelect-select.MuiSelect-outlined.MuiSelect-multiple.MuiInputBase-input.MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
  padding:14px 0 0 20px !important;
  font-size:16px;
}
div.MuiInputBase-root.MuiOutlinedInput-root {
  padding:14px !important;
}
div.MuiSelect-select.MuiSelect-outlined.MuiInputBase-input.MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
  text-align:left !important;
  font-size:16px;
}
.MuiOutlinedInput-notchedOutline.css-1d3z3hw-MuiOutlinedInput-notchedOutline {
  display:none;
}
div.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation1.MuiPaper-root.MuiMenu-paper.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation8.MuiPopover-paper.css-1poimk-MuiPaper-root-MuiMenu-paper-MuiPaper-root-MuiPopover-paper {
  margin:0px;
  left:0px;

}
span.MuiChip-label.MuiChip-labelMedium.css-6od3lo-MuiChip-label {
  font-size:16px;
}
div.MuiButtonBase-root.MuiChip-root.MuiChip-filled.MuiChip-sizeMedium.MuiChip-colorDefault.MuiChip-deletable.MuiChip-deletableColorDefault.MuiChip-filledDefault.MuiAutocomplete-tag.MuiAutocomplete-tagSizeMedium.css-iqd1hh-MuiButtonBase-root-MuiChip-root {
  margin-left:10px !important;
  margin-bottom:10px !important;
}

div.MuiInputBase-root{
}
div.MuiFormControl-root.MuiFormControl-fullWidth.MuiTextField-root {
}
div.MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-colorPrimary {
}

div.card-empty-result {
  display: flex;
  align-items: center;
  justify-content: center;
}
div.card-empty-result div.card-body{
  background:url(../../public/images/adm/notfoundimg.png) no-repeat;
  background-position:bottom center;
  width:310px;
  height:260px;
  margin:80px 0 80px 0;
}
div.card-empty-result div.card-body h4 {
  font-size:20px;
}

fieldset.MuiOutlinedInput-notchedOutline {
  padding-top:0px !important;
  margin-top:0px !important;
  min-height : 56px !important;
  text-align: left !important;
}
div.MuiInputBase-root.MuiOutlinedInput-root {
  padding-top:0px !important;
  margin-top:0px !important;
  min-height : 56px !important;
  text-align: left !important;
}
fieldset.MuiOutlinedInput-notchedOutline {
  display:none;
}

div.title-area-display {
  max-width:450px;
  padding-left:20px;
}
div.title-area-display h1 {
  padding-bottom:15px;
  margin-bottom:0px;
  text-align:left;
  text-transform: uppercase;
  font-weight: normal;
  font-size:25px;
}
div.title-area-display p {
  padding:0px;
  margin:0px;
  text-align:left;
}
div.titleDesc {
  padding-right:10px;
}
div.titleItems {
  padding:0px !important;
}

form.form-area label {
  padding-bottom:15px;
  float:left;
}
form.form-area div.field {
  padding:5px 5px 0 10px;
}
div.radio-box-ls {
  background: #efefef;
  border-radius:6px;
  margin:8px !important;
  padding:8px !important;
}
div.radio-box-ls label {
  font-size:17px;
}
div.radio-box-ls label.title {
  padding:5px 0 0 5px;
}
div.radio-box-ls label.radio {
  margin-right:10px;
}
div.radio-box-ls label.radio input {
  margin-right:10px;
}
div.form-list-header h2 {
  text-transform: uppercase;
  font-weight: normal;
  font-size:18px;
}

button.MuiTab-root {
  font-size:17px;
}

div.list_desc {
  margin:0px;
  padding:0px;
}

div.list_desc div.column{
  margin:0px;
  padding:0px;
}

div.list_desc span.list {
  padding-left:14px;
}

div.list_desc span.list a {
  color:#000;
}
div.list_desc span.list a:hover {
  text-decoration: underline;
}
div.add-field-area {
  margin-top:70px;
}
a.add-field {
  color:#0b4d97 !important;
  margin:45px 0 25px 0;
  font-size: 40px;
}
a.add-field:hover{
  color: #063e7f !important;
}


div.data-list-form {
  padding-right:120px;
}
div.remove-data {
  width:50px;
  height:30px;
  position:absolute;
  right:0px;
  padding-top:50px;
}
div.remove-data a {
  color: #e1e1e1;
}
div.remove-data a:hover{
  color: #bcc2da;
}
div.remove-data svg {
  font-size:30px;
}

svg.MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.MuiSelect-icon {
  margin-right:10px;
  margin-top:-5px;
  font-size:25px;
}

div.MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-colorPrimary.MuiInputBase-formControl.css-1yk1gt9-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root, div.MuiSelect-select.MuiSelect-outlined.MuiSelect-multiple.MuiInputBase-input.MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input, div.MuiInputBase-root.MuiOutlinedInput-root {
  padding-top:0px !important;
  margin-top:0px !important;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  border: 1px solid #b7b7b7;
  -webkit-border-radius: 5px;
  border-radius: 5px;
  -o-text-overflow: clip;
  text-overflow: clip;
  background: rgba(252,252,252,1);
  -webkit-box-shadow: 3px 2px 4px -1px rgba(0,0,0,0.2) inset;
  box-shadow: 3px 2px 4px -1px rgba(0,0,0,0.2) inset;
  height : 56px !important;
}
div.MuiSelect-select.MuiSelect-outlined.MuiSelect-multiple.MuiInputBase-input.MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
  padding:14px 0 0 20px !important;
  font-size:16px;
}
div.MuiInputBase-root.MuiOutlinedInput-root {
  padding:14px !important;
}
div.MuiSelect-select.MuiSelect-outlined.MuiInputBase-input.MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
  text-align:left !important;
  font-size:16px;
}
.MuiOutlinedInput-notchedOutline.css-1d3z3hw-MuiOutlinedInput-notchedOutline {
  display:none;
}
div.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation1.MuiPaper-root.MuiMenu-paper.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation8.MuiPopover-paper.css-1poimk-MuiPaper-root-MuiMenu-paper-MuiPaper-root-MuiPopover-paper {
  margin:0px;
  left:0px;

}
span.MuiChip-label.MuiChip-labelMedium.css-6od3lo-MuiChip-label {
  font-size:16px;
}
div.MuiButtonBase-root.MuiChip-root.MuiChip-filled.MuiChip-sizeMedium.MuiChip-colorDefault.MuiChip-deletable.MuiChip-deletableColorDefault.MuiChip-filledDefault.MuiAutocomplete-tag.MuiAutocomplete-tagSizeMedium.css-iqd1hh-MuiButtonBase-root-MuiChip-root {
  margin-left:10px !important;
  margin-bottom:10px !important;
}

div.MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-colorPrimary.MuiInputBase-fullWidth.MuiInputBase-formControl.css-md26zr-MuiInputBase-root-MuiOutlinedInput-root {
  width:100% !important;
  margin:0px !important;
  padding:0px !important;
  background:none !important;
  float:left;
  max-width:100% !important;
  border:0px !important;
}