div.loading-circle-display {
    width:70px;
    height:70px;
    position:relative;
    margin:0px auto;
}
div.loading-bar {
    width: 250px;
    min-height: 22px;
    text-align: center;
    border-radius: 7px;
    font-size: 14px;
    color: #999;
    position: absolute;
    top: 480px !important;
    left:50% !important;
    transform: translate(-50%,-50%) !important;
    word-break: break-all;
    float:none;
    z-index: 50000;
}
p {
    font-size:18px;
    padding-top:20px;
    color:#000;
}